<!-- 印章管理-查看详情弹框 -->
<template>
  <a-modal
    centered
    :width="658"
    :title="'查看'"
    @cancel="handleClose"
    destroyOnClose
    :footer="null"
    class="check-modal"
  >
    <a-spin :spinning="currentLoading">
      <section class="content show-scrollbar">
        <section>
          <a-descriptions
            title=""
            layout="vertical"
            bordered
            :column="2"
            size="small"
            class="descript"
            style="border-top: 1px solid #f0f0f0"
          >
            <!-- 1，印章名称 -->
            <a-descriptions-item label="印章名称" style="vertical-align: top;">{{
              sealInfo?.sealName || '-'
            }}</a-descriptions-item>
            <!-- 2，印章保管员 -->
            <a-descriptions-item label="印章保管员" v-if="sealInfo.sealType === 1">{{
              sealInfo?.trusteeNames || '-'
            }}</a-descriptions-item>
            <!--3，蘸墨类型 -->
            <a-descriptions-item label="蘸墨类型" v-if="sealInfo.sealType === 1">{{
              sealInfo?.type != null ? sealTypeStatusObj[sealInfo?.type].name : '-'
            }}</a-descriptions-item>
            <!-- 4，特权用印人 -->
            <a-descriptions-item label="特权用印人">{{ sealInfo.sealRelNames || '-' }}</a-descriptions-item>
            <!-- 5，印章状态 -->
            <a-descriptions-item label="印章状态">
              <span
                class="dotted"
                :style="{
                  background: sealInfo?.status === 1 ? '#52C41A' : sealInfo.status == 2 ? '#CCCCCC' : '#C3161C'
                }"
              >
              </span>
              <span>
                {{ sealInfo.status == 1 ? '正常' : sealInfo.status == 2 ? '停用' : '作废' }}
              </span>
            </a-descriptions-item>
            <!-- 6，印章位置 -->
            <a-descriptions-item label="印章位置" v-if="sealInfo.sealType === 1">{{
              sealInfo?.address || '-'
            }}</a-descriptions-item>
            <!-- 7，设备编号 -->
            <a-descriptions-item label="设备编号" v-if="sealInfo.sealType === 1">{{
              sealInfo?.number || '未装在设备上'
            }}</a-descriptions-item>
            <!-- 8，设备状态 -->
            <a-descriptions-item label="连网状态" v-if="sealInfo.sealType === 1 && sealInfo?.model != 'YDA-J'">
              <span class="dotted" :style="{ background: sealInfo?.deviceState === 1 ? '#52C41A' : '#CCCCCC' }"></span
              ><span>{{ sealInfo?.deviceState === 1 ? '在线' : '离线' }}</span>
              <span style="font-size:12px" v-if="sealInfo?.isLock == 1">（已锁定）</span>
            </a-descriptions-item>
            <!-- 9，印章照片 -->
            <a-descriptions-item label="印章照片">
              <img
                v-if="sealInfo.sealImageId !== null"
                class="seal-img"
                :src="sealInfo.fullSealImage"
                v-viewer="printImgOptions"
              />
              <div v-else>-</div>
            </a-descriptions-item>
            <!-- 10. 所属部门 -->
            <a-descriptions-item label="所属部门" style="vertical-align:top">
              {{ sealInfo.departmentName != '' ? sealInfo.departmentName : '-' }}
            </a-descriptions-item>
          </a-descriptions>
          <!-- 11，电子围栏 -->
          <div v-if="sealRailList.length > 0" class="fence">
            <div class="title">电子围栏</div>
            <div class="tab-title">
              <div class="tab-title-line">围栏名称</div>
              <div class="tab-title-line">围栏中心点</div>
              <div class="tab-title-line">围栏半径</div>
            </div>
            <div class="line" v-for="item in sealRailList" :key="item.id">
              <!-- 围栏名称 -->
              <div class="line-box">
                <a-tooltip placement="bottomLeft" v-if="item.railName?.length > 13">
                  <template #title>
                    <span>{{ item.railName }}</span>
                  </template>
                  <div class="line-content">{{ item.railName ?? '-' }}</div>
                </a-tooltip>
                <div class="line-content" v-else>{{ item.railName ?? '-' }}</div>
              </div>
              <!-- 围栏中心点 -->
              <div class="line-box">
                <a-tooltip placement="bottomLeft" v-if="item.railAddress?.length > 13" autoAdjustOverflow>
                  <template #title>
                    <span>{{ item.railAddress }}</span>
                  </template>
                  <div class="line-content">{{ item.railAddress ?? '-' }}</div>
                </a-tooltip>
                <div class="line-content" v-else>{{ item.railAddress ?? '-' }}</div>
              </div>
              <!-- 围栏半径 -->
              <div class="line-box">
                <div class="line-content">{{ item.railRadius }}</div>
              </div>
            </div>
            <div class="border-line"></div>
            <div class="more" v-if="isNeedOpen">
              <img class="icon" src="@/assets/svg/more.svg" />
              <span class="text" @click="openList()">查看更多</span>
            </div>
          </div>
        </section>
      </section>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, watch } from 'vue'
import { sealTypeStatusObj } from '@/utils/constData'
import '@/style/jsx-label-text.scss'
import { sealInfor } from '@/apis/sealManage'

export default defineComponent({
  props: {
    current: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const state = reactive({
      // 展开收起
      isNeedOpen: false,
      // 电子围栏列表
      sealRailList: [],
      // 接口印章详情res.data
      sealInfo: {},
      // 加载loading
      currentLoading: true
    })

    // 详情页数据
    const querySealInfor = async () => {
      const res = await sealInfor({ id: props.current.sealId })
      if (res.success) {
        state.sealInfo = res.data
        // 电子围栏处理
        if (res.data.sealRailList.length > 5) {
          state.sealRailList = res.data.sealRailList.slice(0, 5)
          state.isNeedOpen = true
        } else {
          state.sealRailList = res.data.sealRailList
        }
        setTimeout(() => {
          state.currentLoading = false
        }, 100)
      }
    }

    watch(
      () => props.current,
      newVal => {
        querySealInfor()
      }
    )

    const handleClose = () => {
      emit('close', false)
    }

    // 展开收起
    const openList = () => {
      state.isNeedOpen = false
      state.sealRailList = state.sealInfo.sealRailList
    }

    return {
      ...toRefs(state),
      sealTypeStatusObj,
      handleClose,
      otherOptions: {
        navbar: false,
        title: false,
        transition: false
      },
      openList,
      printImgOptions: {
        navbar: false,
        title: false,
        transition: false,
        viewed(e) {
          if (e.detail.originalImage.dataset.imagetype == 1) {
            this.viewer.rotateTo(0)
          }
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
:deep(.ant-modal-title) {
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  color: rgba(0, 0, 0, 0.85);
  line-height: 25px;
}

:deep(.ant-timeline-item-last) {
  padding-bottom: 0;
}

.content {
  // padding: 24px 24px;
  max-height: 519px;
  overflow-y: auto;

  :deep(.ant-descriptions-item-label) {
    color: rgba(0, 0, 0, 0.45);
  }

  :deep(.ant-descriptions-item-content) {
    color: rgba(0, 0, 0, 0.65);
  }

  .mark {
    > p {
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      margin-bottom: 8px;
    }

    :deep(.ant-input-textarea-show-count::after) {
      top: -25px;
    }
  }

  .descript {
    :deep(.ant-descriptions-item-label) {
      width: 50%;
    }
  }

  :deep(.ant-descriptions-view:nth-of-type(1)) {
    border-top: none;
  }
}

.dotted {
  width: 7px;
  height: 7px;
  margin-right: 5px;
  display: inline-block;
  border-radius: 50%;
  transform: translateY(-1px);
}

.staff-view {
  display: flex;
  flex-wrap: wrap;

  .staff-name {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
}

// .check-modal {
// :deep(.ant-modal-body) {
// padding: 0 !important;
// }
// }

.small-img {
  width: 25%;
  height: 25%;
}

.seal-img {
  width: 60px;
}

// 电子围栏表格
.fence {
  .title {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    margin: 24px 0 8px 0;
  }

  .tab-title {
    padding: 16px 24px;
    background: #fafafa;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex-direction: row;

    .tab-title-line {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);

      &:nth-child(1),
      &:nth-child(2) {
        width: 253px;
      }

      &:nth-child(3) {
        width: 80px;
      }
    }
  }

  .line {
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    border-top: 1px #e8e8e8 solid;

    .line-box {
      text-align: left;

      .line-content {
        max-width: 12em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:nth-child(1),
      &:nth-child(2) {
        width: 253px;
      }

      &:nth-child(3) {
        width: 80px;
      }
    }
  }

  .more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    .icon {
      width: 14px;
      height: 14px;
      margin-bottom: 3px;
    }

    .text {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #c3161c;
      line-height: 20px;
    }
  }

  .border-line {
    height: 1px;
    background: #e8e8e8;
  }
}
</style>
